import axios from 'axios'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ChannelComponentMerchan from '../../../components/ChannelComponentMerchan'
import ChannelComponentSemStaff from '../../../components/ChannelComponentSemStaff'
import InputSelect from '../../../components/Forms/InputSelect'
import Loading from '../../../components/Loading'
import { useAuth } from '../../../contexts/AuthContext'
import { CargoModel } from '../../../data/entities/CargoModel'
import { RankingModel } from '../../../data/entities/RankingModel'
import { LoadCargos } from '../../../domain/use-case/LoadChannels'
import { LoadRankingByCampaing } from '../../../domain/use-case/LoadRankingByCampaing'
import Template from '../../../template'
import * as S from './styled'

interface RankingTridentProps {
  loadCargos: LoadCargos
  loadRankingByCampaing: LoadRankingByCampaing
}

interface Filter {
  channel: any
  cargo: CargoModel
}

enum PageState {
  WAITING_FILTER,
  LOADING,
  READY
}

export default function RankingTrident ({ loadCargos, loadRankingByCampaing }: RankingTridentProps): ReactElement {
  const { doLogout } = useAuth()
  const { campaingId } = useParams()
  const [state, setState] = useState<PageState>(PageState.WAITING_FILTER)
  const [filter, setFilter] = useState<Filter>({} as unknown as Filter)
  const [cargos, setCargos] = useState<CargoModel[]>()
  const [ranking, setRanking] = useState<RankingModel[]>([])

  useEffect(() => {
    (async () => {
      if (!filter.cargo) return
      setState(PageState.LOADING)
      try {
        const results = await loadRankingByCampaing.handle({
          cargoId: filter.cargo.id,
          channelId: filter.channel.id,
          campaingId: 1
        })
        setRanking(results)
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response?.data.message === 'Token inválido') {
            doLogout()
            return
          }
        }
        toast.error('Ocorreu um erro inesperado, por favor tente novamente mais tarde!', { theme: 'colored' })
      }
      setState(PageState.READY)
    })()
  }, [doLogout, campaingId, filter])

  useEffect(() => {
    if (!filter.channel) {
      setState(PageState.WAITING_FILTER)
      return
    }
    (async () => {
      const result = await loadCargos.handle({ channelId: filter.channel.id })
      setCargos(result)
    })()
  }, [filter.channel])

  const handleChangeChannel = useCallback((channel: any) => {
    const cargo = null as unknown as CargoModel
    setFilter({ ...filter, channel, cargo })
    setCargos([])
    setState(PageState.WAITING_FILTER)
  }, [])

  return <Template>
    <S.Container>
      <S.Filter>
        {String(campaingId) !== '2' && <ChannelComponentSemStaff onChange={handleChangeChannel} />}
        {String(campaingId) === '2' && <ChannelComponentMerchan onChange={handleChangeChannel} />}
        <InputSelect label="" onChange={(cargo: string) => setFilter({ ...filter, cargo: JSON.parse(cargo) })}>
          <option value="">Selecione um cargo</option>
          {cargos?.map(cargo => <option key={cargo.id} value={JSON.stringify(cargo)}>{cargo.name}</option>)}
        </InputSelect>
      </S.Filter>

      {state === PageState.LOADING && <Loading />}
      {state === PageState.WAITING_FILTER && <>Selecione o canal e o cargo para ver o ranking</>}
      {state === PageState.READY && <S.RankingContainer>
        <h1>Ranking Final <br />Canal {filter.channel.name}</h1>

        <S.Disclaimer>*Se você não estiver no ranking, não atingiu o target mínimo necessário.</S.Disclaimer>

        <S.TableContainer>
          <S.Table>
            <thead>
              <S.Row>
                <th>{String(campaingId) !== '2' ? 'CLUSTER' : 'LOJA'}</th>
                <th>NOME</th>
                <th>{String(campaingId) !== '2' ? 'GIGAPASSOS' : 'PONTUAÇÃO INDIVIDUAL'}</th>
                <th>{String(campaingId) !== '2' ? 'ACEITE' : 'PONTUAÇÃO LOJA'}</th>
                <th>GANHADOR</th>
              </S.Row>
            </thead>

            <tbody>
              {ranking.map((r) => <S.Row $highlight={r.concorrente}>
                <td>{r.cluster}</td>
                <td>{r.name}</td>
                <td>{r.gigaPassos}</td>
                <td>{r.aceite}</td>
                <td>{r.concorrente ? 'Ganhador' : ''}</td>
              </S.Row>)}

            </tbody>
          </S.Table>
        </S.TableContainer>
      </S.RankingContainer>}
    </S.Container>
  </Template>
}
